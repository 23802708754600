import 'jquery';
import 'alpinejs';
// import barba from '@barba/core';
// import gsap from 'gsap';
import Splide from '@splidejs/splide';
//import lity from 'lity/dist/lity.min';
import lity from 'lity';
import Cookies from 'js-cookie'

const sliders = document.querySelectorAll("[data-slider='splide']");
for (let i = 0; i < sliders.length; i++) {
    new Splide(sliders[i], {
        'arrowPath': 'M12.5,36c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4L26.1,20L11.8,5.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l15,15\n' +
            '\tc0.4,0.4,0.4,1,0,1.4l-15,15C13,35.9,12.8,36,12.5,36z',
    }).mount();
}

$('a[href="#request-callback"]').on("click", function (e) {
    e.preventDefault();
    lity('#request-callback');
});

let noticeBar = document.getElementById('notice-bar');
let closeNoticeBar = document.getElementById("closeNotice");

if (typeof (closeNoticeBar) != 'undefined' && closeNoticeBar != null) {
    closeNoticeBar.onclick = function () {
        noticeBar.classList.add('hide');
        if (!Cookies.get('_noticebar')) {
            Cookies.set('_noticebar', 'hide', {expires: 1});
        }
    };
}

if ($('.acf-map').length > 0) {
    window.gmap_async = $.getScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA4sVJHeSAOMkRkDt2wjhgrttNzHpmxAhE");
    window.gmap_async.done(function () {
        $('.acf-map').each(function () {
            var map = initMap($(this))
        });
    });
}

function initMap($el) {

    var $markers = $el.find('.marker');
    var mapArgs = {
        zoom: $el.data('zoom') || 16,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        streetViewControl: false,
        mapTypeControl: false
    };
    var map = new google.maps.Map($el[0], mapArgs);

    map.markers = [];
    $markers.each(function () {
        initMarker($(this), map);
    });

    centerMap(map);
    return map;
}


function initMarker($marker, map) {

    var lat = $marker.data('lat');
    var lng = $marker.data('lng');
    var latLng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
    };

    var marker = new google.maps.Marker({
        position: latLng,
        map: map
    });

    map.markers.push(marker);

    google.maps.event.addListener(marker, 'click', function () {
        marker.setVisible(false); // maps API hide call
    });

}

function centerMap(map) {

    var bounds = new google.maps.LatLngBounds();
    map.markers.forEach(function (marker) {
        bounds.extend({
            lat: marker.position.lat(),
            lng: marker.position.lng()
        });
    });

    if (map.markers.length == 1) {
        map.setCenter(bounds.getCenter());

    } else {
        map.fitBounds(bounds);
    }
}